/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, SeparateLine, SeparateLineWrap, Text, Image, ColumnCover, ColumnDiv, Fullmap, FullmapWrap, FullmapCover, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1qcp223 --full pb--30 pt--50" name={"uvod"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31167/642baf2c6aaa4808abd0e7a19618f87d_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31167/642baf2c6aaa4808abd0e7a19618f87d_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31167/642baf2c6aaa4808abd0e7a19618f87d_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31167/642baf2c6aaa4808abd0e7a19618f87d_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31167/642baf2c6aaa4808abd0e7a19618f87d_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31167/642baf2c6aaa4808abd0e7a19618f87d_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31167/642baf2c6aaa4808abd0e7a19618f87d_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/31167/642baf2c6aaa4808abd0e7a19618f87d_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--154" content={"<span style=\"color: white\">Karolína <br>&amp;<br>Marcel</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--pbtn2 btn-box--shape5 btn-box--design3 btn-box--shadow3 fs--16 w--300 mt--30" use={"page"} href={"/#program"} style={{"backgroundColor":"var(--color-custom-2)"}} content={"<span style=\"color: var(--white);\">Potvrdit účast<br>Teilnahmebestätigung</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"xax586gfj0f"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--left pb--60 pt--80" name={"informace"} style={{"backgroundColor":"rgba(205,195,173,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72 w--300 ls--0" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-dominant)\">Srdečně Vás zveme na oslavu naší lásky!<br>Wir laden Euch herzlich zur Feier unserer Liebe ein!<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"gtmctxj4ff"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"aboutus"} style={{"backgroundColor":"rgba(205,195,173,1)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: var(--color-blend--95);\">O nás<br>Über uns</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--justify" content={"<span style=\"caret-color: rgb(86, 36, 35); color: rgb(86, 36, 35);\">Jsme trochu blázni. Poprvé jsme si napsali 16. září 2021 na istagramu, 1. října jsme se poprvé viděli a už tenkrát jsme tušili, že to bude velká láska. </span><span style=\"caret-color: rgb(86, 36, 35); color: rgb(86, 36, 35);\">Za rok vztahu jsme pochopili, že už bez sebe nechceme být <br>a </span><span style=\"caret-color: rgb(86, 36, 35); color: rgb(86, 36, 35);\">29. října 2022 jsme se vzali. 23. ledna 2023 se nám narodila dcera Madeleine, což z nás definitivně udělalo rodinu,&nbsp;<br>a 16. září 2023 chceme tuhle naší životní cestu s Vámi oslavit.<br><br></span><span style=\"color: var(--color-custom-1--95);\">Wir sind ein bisschen verrückt.  Wir haben uns am 16. September 2021 zum ersten mal auf Instagram geschrieben, wir haben uns am <br>1. Oktober zum ersten mal gesehen und schon damals wussten wir, dass es eine große Liebe werden würde. Nach einem Jahr Beziehung haben wir verstanden, dass wir nicht mehr ohne einander sein wollen und am 29. Oktober 2022 haben wir geheiratet.  Am 23. Januar 2023 wurde unsere Tochter Madeleine geboren, was uns definitiv zu einer Familie gemacht hat und deswegen wollen wir mit Euch zusammen diese Lebensreise am 16. September 2023 feiern.</span><span style=\"caret-color: rgb(86, 36, 35); color: rgb(86, 36, 35);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"4qqh3gocw59"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--80" name={"informace-2"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--0 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31167/1e2861ce0c8b48d9b7161613357146a7_e=439x0x1469x1469_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31167/1e2861ce0c8b48d9b7161613357146a7_e=439x0x1469x1469_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31167/1e2861ce0c8b48d9b7161613357146a7_e=439x0x1469x1469_s=660x_.jpg 660w, https://cdn.swbpg.com/t/31167/1e2861ce0c8b48d9b7161613357146a7_e=439x0x1469x1469_s=860x_.jpg 860w, https://cdn.swbpg.com/t/31167/1e2861ce0c8b48d9b7161613357146a7_e=439x0x1469x1469_s=1400x_.jpg 1400w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--154" content={"<span style=\"color: white\">16.9.</span>"}>
                    </Title>

                    <Title className="title-box fs--62 mt--02" content={"<span style=\"color: white\">12:00</span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/31167/ef327743db924eae9fb42b08150a08c7_e=397x163x1469x1470_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/31167/ef327743db924eae9fb42b08150a08c7_e=397x163x1469x1470_s=350x_.jpg 350w, https://cdn.swbpg.com/t/31167/ef327743db924eae9fb42b08150a08c7_e=397x163x1469x1470_s=660x_.jpg 660w, https://cdn.swbpg.com/t/31167/ef327743db924eae9fb42b08150a08c7_e=397x163x1469x1470_s=860x_.jpg 860w, https://cdn.swbpg.com/t/31167/ef327743db924eae9fb42b08150a08c7_e=397x163x1469x1470_s=1400x_.jpg 1400w"}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Title className="title-box fs--128 mt--02 pl--0 pr--0" content={"<span style=\"color: white\">Farma<br>Heroutice</span>"}>
                    </Title>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"program-2"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--0 pt--0 flex--center" columns={"1"}>
            
            <ColumnWrapper className="mb--0 mt--0 pb--0 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"os4fcqjhhb"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Fullmap name={"9fwqt9bdpcg"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"10"} place={"Farma Heroutice"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <SeparateLine className="pb--10 pt--10" name={"0n8eipp3aedm"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--50 pt--80" name={"dary"} style={{"backgroundColor":"rgba(205,195,173,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-dominant)\">Svatební dary<br>Hochzeitsgeschenke<br></span>"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-dominant);\">Dary opravdu nejsou nutné, všechny domácí spotřebiče už máme. Hlavně chceme, abyste přijeli! Pokud však na daru trváte, moc nás potěší, když nám přispějete na naše budoucí bydlení.</span><br><br><span style=\"color: var(--color-dominant);\">Wer meint, er möchte uns was schenken, braucht nicht lange nachzudenken. <br>Ihr </span><span style=\"caret-color: rgb(81, 29, 29); color: rgb(81, 29, 29);\">könnt uns eine Freude machen, Lasst einfach unser Konto lachen. <br>Bringt auch noch gute Laune mit, dann wird das Fest ein Riesenhit.&nbsp;</span><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"unyfa3j3ww"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--50 pt--80" name={"polozky"} style={{"backgroundColor":"rgba(205,195,173,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-dominant)\">Dresscode<br></span>"}>
              </Title>

              <Text className="text-box text-box--center lh--2 mt--25" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-custom-1--95);\">Sice se jedná více o oslavu lásky než o svatbu, ale i tak se na to pořádně vyfiknětě, aby Vám to na fotkách a videu všem slušelo&nbsp;(pozn. nebojte se, všechny tradiční prvky svatby budou dodrženy),<br>&nbsp;Naše \"svatba\" nemá určené žádné konkrétní barvy, budeme však rádi, když bílou barvu ponecháte nevěstě. \nPro dámy. Jelikož se obřad bude konat na trávě, jehlové podpatky nedoporučujeme.<br><br>Es geht zwar mehr um ein Fest der Liebe als um eine Hochzeit, erscheint bitte trotzdem im feinen Dress, damit es auf den Fotos und im Video gut aussieht.(Achtung: keine Sorge, alle traditionellen Elemente einer Hochzeit werden beachtet).  Es gibt keine bestimmten Farben für unsere Feierlichkeit, aber wir freuen uns, wenn Ihr der Braut weiß überlasst.  Für die Damen.  Da die Zeremonie auf Rasen stattfindet, empfehlen wir keine Stilettos.&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"bru8obzveyk"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"364wufcqtqw"} style={{"backgroundColor":"rgba(205,195,173,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1--95);\">Ubytování/Unterkunft</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"color: var(--color-custom-1--95);\">Na místě máme dostupné ubytování za poplatek, které je určené převážně pro německé hosty, ale na všem se dá domluvit. V případě zájmu vyplňte ve formuláři potvrzovacím účast u políčka Ubytování \"Ano\" a my se Vám ozveme s detaily.&nbsp;<br><br>Wenn ihr vor Ort übernachten wollt (gegen Aufpreis) , füllt bitte bei der Teilnahmebestätigung die Zeile \"Unterkunft\" mit JA aus. Weitere Informationen folgen dann.<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"program"}>
          
          <ColumnWrap className="column__flex --center el--1 pt--12" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72" style={{"maxWidth":1080}} content={"<span style=\"color: var(--color-custom-1);\">Obdrželi jste pozvánku? Potvrďte svou účast!<br>Hast du eine Einladung bekommen? Bestätige bitte deine Teilnahme!</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"program-2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pb--20">
              
              <ContactForm className="--shape2 --shape3 --shadow4 fs--24 w--500 ls--02 lh--1" style={{"maxWidth":1050}} action={"/contact"} fields={[{"name":"Celé jméno/Vor- und Nachname ","type":"text","required":true,"placeholder":""},{"name":"Telefonní číslo/Telefonnummer","type":"text","required":true,"placeholder":"Telefon +420/+49"},{"name":"Ubytování/Unterkunft","type":"text","required":true,"placeholder":"Ano/Ne - Ja/Nein"},{"name":"Odeslat/Senden","type":"submit","align":"center"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" name={"paticka"} style={{"backgroundColor":"rgba(183,169,138,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant)\">Těšíme se na Vás!<br>Wir freuen uns auf Euch!<br><br>K</span><span style=\"color: var(--color-custom-1--95);\">&amp;M&amp;M</span><span style=\"color: var(--color-dominant)\"><br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}